import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// IMPORT PIPES
import { AsyncStatusPipe } from './asyncstatus.pipe';
import { BeaconRegionPipe } from './beaconregion.pipe';
import { BroadcastModePipe } from './broadcastmode.pipe';
import { CustomDatePipe } from './customdate.pipe';
import { CustomDateTimePipe } from './customdatetime.pipe';
import { CustomTimePipe } from './customtime.pipe';
import { FormatHeaderPipe } from './formatheader.pipe';
import { InitialsPipe } from './initials.pipe';
import { LimitStringPipe } from './limitstring.pipe';
import { MeterPipe } from './meters.pipe';
import { NestingPipe } from './nesting.pipe';
import { OrderByPipe } from './orderby.pipe';
import { PercentPipe } from './percent.pipe';
import { RegionNamePipe } from './regionname.pipe';
import { SafePipe } from './safe.pipe';
import { StateAbbrevPipe } from './stateabbrev.pipe';
import { TimeFormatPipe } from './timeformat.pipe';
import { TrackByPropertyPipe } from './trackbyproperty.pipe';
import { TruncatePipe } from './truncate.pipe';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
	return new TranslateHttpLoader(http, '/assets/languages/', '.json');
}

const pipeArray = [
	AsyncStatusPipe,
	BeaconRegionPipe,
	BroadcastModePipe,
	CustomDatePipe,
	CustomDateTimePipe,
	CustomTimePipe,
	FormatHeaderPipe,
	InitialsPipe,
	LimitStringPipe,
	MeterPipe,
	NestingPipe,
	OrderByPipe,
	PercentPipe,
	RegionNamePipe,
	SafePipe,
	StateAbbrevPipe,
	TimeFormatPipe,
	TrackByPropertyPipe,
	TruncatePipe,
];

@NgModule({
	imports: [
		CommonModule,
		TranslateModule.forRoot({
			defaultLanguage: 'en-US',
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	declarations: pipeArray,
	exports: pipeArray,
})
export class PipeModule {}
