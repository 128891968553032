import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BroadcastService {
	public authError: Subject<any>;
	public responseError: Subject<any>;

	constructor() {
		//initialize it
		this.authError = new Subject<any>();
		this.responseError = new Subject<any>();
	}
}
