import { Component, Input, OnInit, forwardRef } from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	Validator,
} from '@angular/forms';

import { ModalSizes, ProfileType } from '@fmlib/enums';
import { ApiService, GlobalService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { MatDialog } from '@angular/material/dialog';
import { SelectMultiDialogComponent } from '../../dialogs/select-multi-dialog/select-multi-dialog.component';

import { reject, sortBy } from 'lodash-es';

@Component({
	selector: 'list-editor',
	templateUrl: './list-editor.component.html',
	styleUrls: ['./list-editor.component.less'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ListEditorComponent),
			multi: true,
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => ListEditorComponent),
			multi: true,
		},
	],
})
export class ListEditorComponent implements OnInit, ControlValueAccessor, Validator {
	@Input() type: ProfileType;
	@Input() readonly: boolean;

	@Input() checkFilter: boolean;

	list;
	global: GlobalStore;
	globalList;
	isFixed: boolean;
	isValid: boolean;

	ProfileType = ProfileType;

	constructor(
		private dialog: MatDialog,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		if (!this.list) {
			this.list = [];
			this.onChange(this.list);
		}

		this.isFixed = false;
		this.globalList = [];

		if (this.checkFilter) {
			this.applyGlobalFilter();
		}
	}

	applyGlobalFilter(): void {
		// DO WE HAVE A GLOBAL FILTER
		let globalFilter = this.global.permissionFilters.find(
			({ property }) => property === this.type
		);
		globalFilter = globalFilter ? JSON.parse(globalFilter.filter) : null;

		if (globalFilter && globalFilter._id && globalFilter._id.$in) {
			this.globalList = globalFilter._id.$in;
		}

		// SHOULD WE ADD THE REQUIRED VALIDATOR
		if (this.globalList && this.globalList.length === 1) {
			this.isFixed = true;
			this.FM_Api.connect(this.type + 's').get({ id: this.globalList[0] }, (response) => {
				this.list = [response];
			});
		}
	}

	chooseItems(): void {
		const dialogRef = this.dialog.open(SelectMultiDialogComponent, {
			panelClass: ModalSizes.Select,
			data: {
				settings: { type: this.type },
				selected: this.list,
			},
		});

		dialogRef.afterClosed().subscribe((selected) => {
			if (selected) {
				this.list = sortBy(selected, 'name');
				this.onChange(this.list);
			}
		});
	}

	removeItem(d): void {
		this.list = reject(this.list, { id: d.id });
		this.onChange(this.list);
	}

	removeAllItems(): void {
		this.list = [];
		this.onChange(this.list);
	}

	// NG MODEL FUNCTIONS
	writeValue(value: any): void {
		this.list = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		// do nothing
	}

	onChange = (_: any): any => {};
	onTouched = (_: any): any => {};

	// VALIDATION
	validate(control: AbstractControl): ValidationErrors {
		this.isValid = true;

		// REQUIRE LIST
		if (this.globalList && this.globalList.length > 1) {
			if (!this.list || !this.list.length) {
				this.isValid = false;
			}
		}

		return this.isValid ? null : { invalid: true };
	}

	onValidationChange = (): any => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidationChange = fn;
	}
}
