import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, DataService, GlobalService, MessageService } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep } from 'lodash-es';
import { EMPTY, catchError } from 'rxjs';

@Component({
	selector: 'create-sitetype-dialog',
	templateUrl: './create-sitetype-dialog.component.html',
})
export class CreateSitetypeDialogComponent implements OnInit {
	global: GlobalStore;
	obj;
	isDuplicate: boolean;
	dup;
	profile;
	settings;
	sitetypeModes: string[];

	constructor(
		//
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<CreateSitetypeDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(DataService) private FM_Data: DataService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(MessageService) private FM_Messages: MessageService
	) {
		this.global = this.FM_Global.get();

		this.settings = {
			endpoint: this.FM_Api.connect('sitetypes'),
		};
	}

	ngOnInit(): void {
		// SCOPE VARIABLES
		this.profile = {
			type: 'sitetype',
			isLoading: false,
			isFailed: false,
		};

		this.initItem();
	}

	initItem(): void {
		this.profile.isLoading = true;

		if (this.data.selected) {
			this.obj = cloneDeep(this.data.selected);
			this.profile.title = 'EDIT_SITETYPE';

			if (this.obj.isCustomer) {
				this.obj.sitetypeMode = 'customer';
			} else if (this.obj.isDistributor) {
				this.obj.sitetypeMode = 'distributor';
			} else if (this.obj.isFacility) {
				this.obj.sitetypeMode = 'facility';
			} else if (this.obj.isSupplier) {
				this.obj.sitetypeMode = 'supplier';
			} else if (this.obj.isTransit) {
				this.obj.sitetypeMode = 'transit';
			}
		} else {
			// NEW SITETYPE
			this.obj = {};
			this.profile.title = 'NEW_SITETYPE';
		}

		this.sitetypeModes = ['customer', 'distributor', 'facility', 'supplier', 'transit'];

		setTimeout(() => {
			this.profile.isLoading = false;
		}, 100);
	}

	saveComplete(response, message): void {
		this.FM_Messages.addSuccessMessage(message);
		this.dialogRef.close(response);
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	saveError(): void {
		this.FM_Messages.addErrorMessage('REQUEST_ERROR');
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	save(): void {
		this.profile.isBusy = true;

		// PREPARE THE SAVE
		let temp = {
			name: this.obj.name,
			isActive: this.obj.isActive,
			isCustomer: this.obj.sitetypeMode === 'customer',
			isDistributor: this.obj.sitetypeMode === 'distributor',
			isFacility: this.obj.sitetypeMode === 'facility',
			isSupplier: this.obj.sitetypeMode === 'supplier',
			isTransit: this.obj.sitetypeMode === 'transit',
			description: this.obj.description,
			tags: this.obj.tags,
		};

		// FLATTENT THE DATA
		temp = this.FM_Data.flattenData(temp);

		if (this.isDuplicate) {
			temp.name = this.dup.name;
		}

		// SAVE OR UPDATE
		if (!this.obj.id || this.isDuplicate) {
			this.settings.endpoint.post(
				temp,
				(response) => {
					let message;

					if (this.isDuplicate) {
						message = 'DUPLICATED_SITETYPE';
					} else {
						message = 'CREATED_SITETYPE';
					}

					this.saveComplete(response, message);
				},
				() => {
					this.saveError();
				}
			);
		} else {
			this.settings.endpoint
				.put({ id: this.obj.id }, temp)
				.pipe(
					catchError(() => {
						this.saveError();
						return EMPTY;
					})
				)
				.subscribe((response) => {
					this.saveComplete(response, 'UPDATED_SITETYPE');
				});
		}
	}

	hasErrors(): boolean {
		return false;
	}

	// SAVE
	ok(form): void {
		if (form.valid) {
			this.save();
		} else {
			this.FM_Messages.addErrorMessage('INVALID_FORM');
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
