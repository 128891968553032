import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FilterStatus, ProfileType } from '@fmlib/enums';
import { GlobalStore } from '@fmlib/interfaces';
import { ApiService, GlobalService, SortService } from '@fm/services';

import { cloneDeep, isEmpty } from 'lodash-es';

import { EMPTY, catchError, forkJoin, tap } from 'rxjs';

interface FMSearch {
	objtype: ProfileType;
	filterType: ProfileType;
	filters: any;
	populate: any;
	select: string;
	endpoint: any;

	// TOGGLES
	hideAdd: boolean;
	showPartners: boolean;
}

@Injectable()
export class SearchService {
	global: GlobalStore;

	public objtype: ProfileType;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private FM_Api: ApiService,
		private FM_Global: GlobalService,
		private FM_Sort: SortService
	) {
		this.global = this.FM_Global.get();
	}

	get oneDay(): Date {
		return new Date(new Date().getTime() - 24 * 60 * 60 * 1000);
	}

	clone(obj): any {
		return cloneDeep(obj);
	}

	getEndpoint(type: string): any {
		switch (type) {
			case ProfileType.COMPANY:
				return this.FM_Api.connect('companies');
			case ProfileType.PUSH:
				return this.FM_Api.connect('pushes');
			default:
				return this.FM_Api.connect(type + 's');
		}
	}

	init(searchtype: ProfileType): Partial<FMSearch> {
		this.objtype = searchtype;

		const obj: Partial<FMSearch> = {
			objtype: searchtype,
			filterType: searchtype,
			filters: {},
			select: this.selectProps(searchtype),
		};

		obj.endpoint = this.getEndpoint(searchtype);

		// UNIQUE SETTINGS
		switch (searchtype) {
			case ProfileType.APP:
			case ProfileType.ASSET:
				obj.showPartners = true;
				break;
			case ProfileType.COMPANY:
				obj.populate = {
					path: 'parent',
					select: 'name',
				};
				break;
			case ProfileType.BEACON:
			case ProfileType.NFC:
				obj.hideAdd = true;
				break;
			case ProfileType.DEVICE:
				obj.hideAdd = true;
				obj.showPartners = true;
				break;

			case ProfileType.EXPERIENCE:
			case ProfileType.JOURNEY:
				obj.populate = {
					path: 'payload',
					select: 'name type',
				};
				break;
			case ProfileType.SITE:
			case ProfileType.TASK:
			case ProfileType.ZONE:
				obj.showPartners = true;
				break;
			case ProfileType.PARTNER:
				obj.populate = {
					path: 'partner',
					select: 'name image',
				};
				break;
			default:
		}

		return obj;
	}

	checkBatch(batchId: string): Promise<any> {
		return new Promise((resolve) => {
			this.FM_Api.connect('batchprogress').get({ id: batchId }, (res) => {
				if (res.percentComplete === 100) {
					resolve(res);
					return;
				} else {
					setTimeout(() => {
						this.checkBatch(batchId).then(resolve);
					}, 400);
				}
			});
		});
	}

	// REWRITE WITH RXJS
	exportSearch(search, runFormat = true): Promise<any> {
		return new Promise((resolve) => {
			const exportParams: any = {};

			if (runFormat) {
				// FORMAT THE PARAMS
				this.formatFilters(exportParams, search.filters);
			} else {
				exportParams.conditions = search.filters;
			}

			this.FM_Api.post('exportdata', {
				objectType: search.objtype + 'csv',
				includeShared: exportParams.includeShared,
				partners: exportParams.partners,
				query: JSON.stringify(exportParams.conditions),
			})
				.pipe(
					tap((res) => {
						this.checkBatch(res.batchId).then((r) => {
							let link;
							if (r.filename) {
								link = `${this.global.cdn.internal}${r.pathFragment}/${r.filename}${r.sas}`;
							}
							resolve(link);
						});
					})
				)
				.subscribe();
		});
	}

	getSort(search): any {
		// let storedSort = sessionStorage.getItem('fm_sort');

		if (search.sort) {
			return search.sort.order + search.sort.option.id;
		} else {
			const s = this.FM_Sort.getDefault(search.objtype);

			return s.order + s.option.id;
		}
	}

	selectProps(type: ProfileType): string {
		const props = {
			adminuser:
				'isLockedOut isSuper lastLockedOut lastLogin firstname lastname email username groupName groups image require2FA',
			app: 'isShared company image',
			asset: 'isShared company beaconCount beaconCounts shippedToQuantity currentCount seenCount nfcCount experienceCount images assetType divisionName supplierName percentExecuted',
			beacon: 'battery advertisementInterval mac firmwareVersion beaconType broadcastTypes radius regionId txPower lat lng lastSeen lastLocationUpdate assetId assetName site siteName zone zoneName',
			company: 'isDemo image expires parent children partnerCount',
			device: 'status tasks',
			experience: 'isExpired payload maxAllowed timesDelivered assetCount zoneCount',
			gateway:
				'battery mac beaconCounts lastBeaconCount lastReceivedData history site siteName zone zoneName gatewayType isSpare',
			geofence: 'vertex site siteName radius zoneCount',
			group: 'description',
			nfc: 'nfcType lat lng lastSeen lastLocationUpdate asset assetName site siteName zone zoneName',
			journey: 'payload steps maxAllowed timesDelivered',
			partner:
				'company companyName partner partnerName appMode assetMode siteMode zoneMode appCount assetCount siteCount zoneCount shareApps shareAssets shareSites shareZones',
			payload: 'action content type',
			program: '',
			push: 'apps',
			site: 'isShared company bounceDuration street city state postalCode visitDuration assetCount beaconCount nfcCount zoneCount lat lng mapZoom',
			task: 'siteCount zoneCount',
			zone: 'isShared isSiteWide sitewideType company beaconCount geofenceCount nfcCount siteCount experienceCount zoneType',
		};

		// MERGE DEFAULT
		return 'name updated isActive isArchived ' + props[type];
	}

	setUrl(search): void {
		let test;
		const url: any = {};

		// STRING AND PARSE TO REMOVE UNDEFINED
		if (search.filters) {
			test = JSON.parse(JSON.stringify(search.filters));
		}

		if (!isEmpty(test)) {
			url.conditions = JSON.stringify(test);
		} else {
			url.conditions = null;
		}

		// SHOULD WE SHOW SORT IN URL?
		if (!isEmpty(search.sort)) {
			url.sort = search.sort.order + search.sort.option.id;
		}

		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: url,
			queryParamsHandling: 'merge', // remove to replace all query params by provided
		});
	}

	batchProcess(type: string, id: string, changes: any): Promise<any> {
		return new Promise((resolve) => {
			const obs = [];
			const addIds = changes.adds.map((o) => o.id);
			const removeIds = changes.removes.map((o) => o.id);

			if (changes.useFilter) {
				obs.push(
					this.FM_Api.post('objectbatch', {
						objectType: type,
						parentObjectId: id,
						action: changes.batchAction,
						query: JSON.stringify(changes.filter.conditions),
					})
				);
			} else {
				// BATCH INSERTS
				if (addIds.length) {
					obs.push(
						this.FM_Api.post('objectbatch', {
							objectType: type,
							parentObjectId: id,
							action: 'insert',
							ids: addIds,
						})
					);
				}

				// BATCH INSERTS
				if (removeIds.length) {
					obs.push(
						this.FM_Api.post('objectbatch', {
							objectType: type,
							parentObjectId: id,
							action: 'delete',
							ids: removeIds,
						})
					);
				}
			}

			forkJoin(obs).subscribe((response) => {
				if (response.length) {
					this.checkBatch(response[0].batchId).then(() => {
						resolve(null);
					});
				} else {
					resolve(null);
				}
			});
		});
	}

	batchRemove(type: string, id: string): Promise<any> {
		return new Promise((resolve) => {
			this.FM_Api.post('objectbatch', {
				objectType: type,
				parentObjectId: id,
				action: 'delete',
				query: JSON.stringify({}),
			}).pipe(
				tap((res) => {
					this.checkBatch(res.batchId).then(() => {
						resolve(null);
					});
				}),
				catchError(() => {
					resolve(null);
					return EMPTY;
				})
			);
		});
	}

	goToItem(...params: string[]): void {
		this.router.navigate(params);
	}

	formatFilters(params, filters): any {
		/*
		// INIT CONDITIONS
		params.conditions = {};

		// FORCE A COMPANY IF NOT SET
		if(!params.jedi && !params.allPartners && !params.conditions.company) {
			params.conditions.company = glob.company.id;
		}
		*/

		const cond = cloneDeep(filters);

		// default status when not set
		if (!cond.status) {
			if (this.objtype === ProfileType.GATEWAY) {
				cond.status = [FilterStatus.ACTIVE, FilterStatus.NOACTIVITY];
			}
		}

		if (cond.status?.length) {
			cond.$or = [];

			if (cond.status.includes(FilterStatus.ACTIVE)) {
				// SPECIAL GATEWAY TREATMENT
				if (this.objtype === ProfileType.GATEWAY) {
					cond.$or.push({
						isActive: true,
						lastReceivedData: {
							$gte: this.oneDay,
						},
					});
				} else {
					cond.$or.push({ isActive: true });
				}
			}

			if (cond.status.includes(FilterStatus.INACTIVE)) {
				cond.$or.push({ isActive: false });
			}

			if (cond.status.includes(FilterStatus.ARCHIVED)) {
				cond.$or.push({ isArchived: true });
			}

			if (cond.status.includes(FilterStatus.NOACTIVITY)) {
				const f = [];
				f.push({ isActive: true });
				f.push({
					$or: [{ lastReceivedData: null }, { lastReceivedData: { $lt: this.oneDay } }],
				});

				cond.$or.push({ $and: f });
			}

			if (cond.status.includes(FilterStatus.SPARE)) {
				cond.$or.push({ isSpare: true });
			}

			if (!cond.$or.length) {
				delete cond.$or;
			}

			delete cond.status;
		} else {
			delete cond.status;
		}

		if (cond.partners) {
			if (cond.partners === 'all') {
				params.allPartners = true;
				delete cond.partners;
			} else {
				params.partners = JSON.stringify(cond.partners);
				delete cond.partners;
			}
			params.includeShared = true;
		}

		// CONVERT FILTER NAME
		if (cond.name && cond.name !== '') {
			cond.searchText = {
				$regex: cond.name.join('|').toLowerCase(),
			};
			delete cond.name;
		}

		// CONVERT QUICKSEARCH
		if (cond.quicksearch && cond.quicksearch !== '') {
			// cond.searchText = {'$regex': cond.quicksearch.join('|').toLowerCase()};
			const terms = [];
			cond.quicksearch.forEach((item) => {
				terms.push({ searchText: { $regex: item.toLowerCase() } });
			});
			cond.$and = terms;

			delete cond.quicksearch;
		}

		//CONVERT ASSET
		if (cond.apps && cond.apps !== '') {
			cond.apps = { $in: cond.apps };
		}

		//CONVERT ASSET
		if (cond.asset && cond.asset !== '') {
			cond.asset = { $in: cond.asset };
		}

		//CONVERT GROUPS
		if (cond.groups && cond.groups !== '') {
			cond.groups = { $in: cond.groups };
		}

		//CONVERT battery
		if (cond.battery && cond.battery !== '') {
			cond.battery = { $lt: cond.battery };
		}

		//CONVERT intervals
		if (cond.advertisementInterval && cond.advertisementInterval !== '') {
			cond.advertisementInterval = { $in: cond.advertisementInterval };
		}

		//CONVERT visitDuration
		if (cond.visitDuration && cond.visitDuration !== '') {
			cond.visitDuration = { $lt: cond.visitDuration };
		}

		// CONVERT DIVISION(s)
		if (cond.division && cond.division.length) {
			cond.division = { $in: cond.division };
		}

		if (cond.divisions && cond.divisions.length) {
			cond.divisions = { $in: cond.divisions };
		}

		if (cond.geocoordinates && cond.geocoordinates !== '') {
			cond.location = {
				$near: {
					$geometry: {
						type: 'Point',
						coordinates: [
							String(cond.geocoordinates.lng),
							String(cond.geocoordinates.lat),
						],
					},
					$maxDistance: cond.geocoordinates.distance,
				},
			};

			delete cond.geocoordinates;
		}

		//CONVERT PARTNERS
		if (cond.partners && cond.partners !== '') {
			cond.partners = { $in: cond.partners };
		}

		// CONVERT PAYLOADS
		if (cond.payload && cond.payload !== '') {
			cond.payload = { $in: cond.payload };
		}

		//CONVERT RANGES
		if (cond.ranges && cond.ranges !== '') {
			const ranges = [];

			cond.ranges.forEach((item) => {
				const r = item.split('-');

				const t = {
					$and: [
						{
							assetId: { $gte: parseInt(r[0], 10) },
						},
						{
							assetId: { $lte: parseInt(r[1], 10) },
						},
					],
				};

				ranges.push(t);
			});

			cond.$or = ranges;

			delete cond.ranges;
		}

		//CONVERT REGION
		if (cond.region && cond.region !== '') {
			cond.region = { $in: cond.region };
		}

		// CONVERT SITE
		if (cond.site && cond.site !== '') {
			cond.site = { $in: cond.site };
		}

		if (cond.sites && cond.sites !== '') {
			cond.sites = { $in: cond.sites };
		}

		//CONVERT STATE
		if (cond.state && cond.state !== '') {
			cond.state = { $in: cond.state };
		}

		// CONVERT SITES
		if (cond.steps && cond.steps !== '') {
			cond.steps = {
				$elemMatch: {
					id: {
						$in: cond.steps,
					},
				},
			};
		}

		//CONVERT SUPPLIER
		if (cond.suppliers && cond.suppliers !== '') {
			cond.suppliers = { $in: cond.suppliers };
		}

		//CONVERT ZONE
		if (cond.zone && cond.zone !== '') {
			cond.zone = { $in: cond.zone };
		}

		//CONVERT ZONES
		if (cond.zones && cond.zones !== '') {
			cond.zones = { $in: cond.zones };
		}

		//CONVERT TAGS
		if (cond.tags && cond.tags !== '') {
			cond.tags = { $in: cond.tags };
		}

		//CONVERT ASSETTYPE
		if (cond.assetType && cond.assetType !== '') {
			cond.assetType = { $in: cond.assetType };
		}

		//CONVERT GATEWAYTYPE
		if (cond.gatewayType && cond.gatewayType !== '') {
			cond.gatewayType = { $in: cond.gatewayType };
		}

		//CONVERT SITETYPE
		if (cond.siteType && cond.siteType !== '') {
			cond.siteType = { $in: cond.siteType };
		}

		//CONVERT ZONETYPE
		if (cond.zoneType && cond.zoneType !== '') {
			cond.zoneType = { $in: cond.zoneType };
		}

		params.conditions = cond;

		// SET THE JEDI BIT
		if (filters.jedi) {
			params.jedi = true;
		} else {
			delete params.jedi;
		}

		// REMOVE JEDI FROM FILTERS
		delete params.conditions.jedi;

		/*
		// HIDE ARCHIVED ITEMS
		if (!filters.isArchived) {
			params.conditions.isArchived = false;
		}
		*/

		return params;
	}
}
