import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'timeFormat' })
export class TimeFormatPipe implements PipeTransform {
	convertTime(m: number): any {
		const mins = m % 60;
		const t = Math.floor(m / 60);
		const hours = t % 24;
		const days = Math.floor(t / 24);
		return { d: days, h: hours, m: mins };
	}

	transform(value: number): any {
		const time = this.convertTime(value);
		const formatted = [];

		if (time.d >= 1) {
			formatted.push(time.d + 'd');
		}

		if (time.h >= 1) {
			formatted.push(time.h + 'h');
		}

		if (time.m > 0) {
			formatted.push(time.m + 'm');
		}

		return formatted.join(' ');
	}
}
