import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';

import { ProfileType } from '@fmlib/enums';
import { FilterService, UtilsService } from '@fm/services';

import { cloneDeep, isEmpty, isEqual } from 'lodash-es';

@Component({
	selector: 'filter-dates',
	templateUrl: './filter-dates.component.html',
})
export class FilterDatesComponent implements OnInit, OnChanges {
	@Input() filter;
	@Input() objtype;
	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	private currentFilter;
	private activeMode;

	activeFilter;
	dates;
	dateMode;
	dateModes: { key: string; label: string }[];
	dateFilters: any[];
	dateOptions;

	constructor(
		private translate: TranslateService,
		private FM_Filters: FilterService,
		private FM_Utils: UtilsService
	) {}

	ngOnInit(): void {
		this.dates = {};

		this.dateFilters = this.FM_Filters.getDateFilters();

		this.dateFilters.push({
			id: 'custom',
			name: this.translate.instant('CUSTOM'),
		});

		this.dateModes = [
			{ key: 'updated', label: 'UPDATED' },
			{ key: 'created', label: 'CREATED' },
		];

		switch (this.objtype) {
			case ProfileType.ADMINUSER:
				this.dateModes.push({ key: 'lastLogin', label: 'LAST_LOGIN' });
				break;
			case ProfileType.BEACON:
				this.dateModes.push({ key: 'lastSeen', label: 'LAST_SEEN' });
				this.dateModes.push({ key: 'lastLocationUpdate', label: 'LAST_LOCATION' });
				break;
			case ProfileType.GATEWAY:
				this.dateModes.push({ key: 'lastReceivedData', label: 'LAST_SEEN' });
				break;
		}

		this.dateOptions = {
			formatYear: 'yy',
			initDate: new Date(),
			showWeeks: false,
		};

		this.initFilter();
	}

	ngOnChanges(): void {
		if (isEmpty(this.filter)) {
			this.clearFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		this.filter = {};
		if (this.dates.startDate || this.dates.endDate) {
			this.filter[this.dateMode] = {};
			if (this.dates.startDate) {
				this.filter[this.dateMode].$gte = this.FM_Utils.getUtcStart(this.dates.startDate);
			}

			if (this.dates.endDate) {
				this.filter[this.dateMode].$lt = this.FM_Utils.getUtcEnd(this.dates.startDate);
			}
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	setItem(): void {
		this.runupdate();
	}

	// FILTERING
	loadFilter(obj): void {
		this.activeFilter = obj;

		if (obj.id === 'custom') {
			this.dates.startDate = null;
			this.dates.endDate = null;
		} else {
			this.dates = {};

			if (obj.filter) {
				this.dates.startDate = obj.filter.$gte;
				this.dates.endDate = obj.filter.$lt;
			}

			this.runupdate();
		}
	}

	isActive(f): boolean {
		return this.activeFilter === f;
	}

	dateFilter(): void {
		this.runupdate();
	}

	updateDate(dateType: string, event: MatDatepickerInputEvent<Date>): void {
		if (dateType === 'start') {
			this.dates.startDate = event.value;
		} else if (dateType === 'end') {
			this.dates.endDate = event.value;
		}

		this.runupdate();
	}

	clearDate(dateType: string): void {
		if (dateType === 'start') {
			this.dates.startDate = null;
		} else if (dateType === 'end') {
			this.dates.endDate = null;
		}

		this.runupdate();
	}

	setDateMode(): void {
		delete this.filter[this.activeMode];

		this.activeMode = this.dateMode;
		this.runupdate();
	}

	initFilter(): void {
		if (!isEmpty(this.filter)) {
			this.dateMode = Object.keys(this.filter)[0];
			this.currentFilter = cloneDeep(this.filter[this.dateMode]);

			// CHECK FOR ACTIVE FILTER
			this.activeFilter = this.dateFilters.find((item) => {
				if (isEqual(item.filter, this.filter[this.dateMode])) {
					return item;
				}
			});

			if (!this.activeFilter) {
				this.activeFilter = this.dateFilters[this.dateFilters.length - 1];
			}

			if (this.filter[this.dateMode]) {
				this.dates.startDate = this.filter[this.dateMode].$gte;
				this.dates.endDate = this.filter[this.dateMode].$lt;
			}
		} else {
			this.dateMode = this.dateModes[0].key;
			this.dates = {};
		}

		this.activeMode = this.dateMode;
	}

	clearFilter(): void {
		if (!isEmpty(this.filter) && this.dateMode) {
			delete this.filter[this.dateMode];
		}
		this.dates = {};
		this.activeFilter = null;
	}
}
