import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Beacon, Site } from '@fmlib/interfaces';

@Injectable({ providedIn: 'root' })
export class MapsService {
	private circleColor = '#00FF00';

	readonly DEFAULT_POSITION = {
		lat: 51.219053,
		lng: 4.404418,
	};

	readonly DEFAULT_ZOOM = 17;

	constructor(private router: Router) {}

	getCircle(vertex, radius: number): google.maps.Circle {
		return new google.maps.Circle({
			strokeColor: this.circleColor,
			strokeOpacity: 0.8,
			strokeWeight: 2,
			fillColor: this.circleColor,
			fillOpacity: 0.2,
			center: new google.maps.LatLng(vertex.x, vertex.y),
			radius: radius,
		});
	}

	mapPin(color: string): any {
		return {
			path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z',
			fillColor: color,
			fillOpacity: 1,
			strokeColor: '#000',
			strokeWeight: 1,
			scale: 1,
			labelOrigin: new google.maps.Point(0, -29),
		};
	}

	deg2rad(deg: number): number {
		return deg * (Math.PI / 180);
	}

	/**
	 * This method will compare two sets of lat/lng
	 * @param {number} lat1
	 * @param {number} lng1
	 * @param {number} lat2
	 * @param {number} lng2
	 * @returns {number} distance in KM
	 */

	compareLatLng(lat1: number, lng1: number, lat2: number, lng2: number): number {
		const R = 6371; // Radius of the earth in km
		const dLat = this.deg2rad(lat2 - lat1); // deg2rad below
		const dLng = this.deg2rad(lng2 - lng1);
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(this.deg2rad(lat1)) *
				Math.cos(this.deg2rad(lat2)) *
				Math.sin(dLng / 2) *
				Math.sin(dLng / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		const d = R * c; // Distance in km
		return d;
	}

	getMarker(position: google.maps.LatLng, label: string): any {
		return {
			id: 0,
			label: label,
			position: {
				lat: position.lat(),
				lng: position.lng(),
			},
			options: {},
		};
	}

	getBeaconMarker(beacon: Beacon): any {
		return {
			id: 1,
			label: 'Se',
			position: {
				lat: beacon.lat,
				lng: beacon.lng,
			},
			options: {
				icon: this.mapPin('#9accca'),
			},
		};
	}

	getSiteMarker(site: Site): any {
		return {
			id: 0,
			label: 'Si',
			position: {
				lat: site.lat,
				lng: site.lng,
			},
			options: {
				// icon: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
			},
		};
	}

	goToItem(...params: string[]): void {
		this.router
			.navigateByUrl('/', { skipLocationChange: true })
			.then(() => this.router.navigate(params));
	}
}
