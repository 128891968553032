import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, ExperienceService, GlobalService } from '@fm/services';

import { GlobalStore } from '@fmlib/interfaces';

import { cloneDeep, reject, sortBy } from 'lodash-es';

@Component({
	selector: 'select-multi-dialog',
	templateUrl: './select-multi-dialog.component.html',
})
export class SelectMultiDialogComponent implements OnInit, AfterViewInit {
	global: GlobalStore;
	public pageTitle: string;
	public search;
	public settings;

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<SelectMultiDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(ExperienceService) private FM_Experience: ExperienceService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		// SEARCH OBJECT
		this.search = {
			isLoading: false,
			isFailed: false,
		};

		this.settings = cloneDeep(this.data.settings);

		switch (this.settings.type) {
			case 'company':
				this.settings.endpoint = this.FM_Api.connect('companies');
				this.settings.populate = 'parent';
				this.pageTitle = 'COMPANIES';
				break;
			case 'experience':
				this.settings.endpoint = this.FM_Api.connect('experiences');
				this.settings.populate = this.FM_Experience.populate();
				this.pageTitle = 'EXPERIENCES';
				break;
			case 'partner':
				this.settings.endpoint = this.FM_Api.connect('partners');
				this.settings.filter = { partner: this.global.company.id };
				this.pageTitle = 'PARTNERS';
				break;
			default:
				this.settings.endpoint = this.FM_Api.connect(this.settings.type + 's');
				this.pageTitle = this.data.settings.type.toUpperCase() + 'S';
		}

		this.search.selected = this.data.selected ? cloneDeep(this.data.selected) : [];

		this.initSearch();
	}

	ngAfterViewInit(): void {
		this.searchInput.nativeElement.focus();
	}

	checkSelected(): void {
		// FIND THE SELECTED ITEMS
		this.search.list.forEach((item) => {
			item.isSelected = this.search.selected.some((i) => {
				switch (this.settings.type) {
					case 'bannerrestriction':
						return i.name === item.name;
					default:
						return i.id === item.id;
				}
			});
		});
	}

	initSearch(): void {
		this.search.page = 1;
		this.search.pages = 1;
		this.search.list = [];
		this.search.count = 0;

		this.loadList();
	}

	performSearch(params): any {
		return new Promise((resolve) => {
			if (this.search.qs && this.search.qs !== '') {
				params.conditions.searchText = { $regex: this.search.qs.toLowerCase() };
			}

			this.settings.endpoint.query(params, (data, headers) => {
				this.search.pages = parseInt(headers.get('X-Pages-Total'), 10);
				this.search.total = parseInt(headers.get('X-Records-Total'), 10);

				this.search.list = this.search.list.concat(data);
				this.search.count = this.search.list.length;
				this.search.page = this.search.page + 1;

				this.search.isLoading = false;

				// ON COMPLETE
				resolve(null);
			});
		});
	}

	loadList(): void {
		if (this.search.isLoading || this.search.page > this.search.pages) return;

		this.search.isLoading = true;

		const params = {
			page: this.search.page,
			sort: 'name',
			conditions: {},
		};

		if (this.settings.filter) {
			params.conditions = cloneDeep(this.settings.filter);
		}

		this.performSearch(params).then(() => {
			this.checkSelected();
		});
	}

	// SELECT ALL
	selectAll(): void {
		// CHECK IF ALL LOADED
		if (this.search.list.length === this.search.total) {
			this.search.selected = this.search.list;

			this.search.list.forEach((item) => {
				item.isSelected = true;
			});
		} else {
			this.search.list = [];

			if (this.search.isLoading) return;
			this.search.isLoading = true;

			const params = {
				page: 1,
				sort: 'name',
				conditions: {},
				per_page: this.search.total,
			};

			this.performSearch(params).then(() => {
				this.search.selected = this.search.list;

				this.search.list.forEach((item) => {
					item.isSelected = true;
				});
			});
		}
	}

	// CLEAR ALL
	clearAll(): void {
		this.search.selected = [];

		this.search.list.forEach((item) => {
			item.isSelected = false;
		});
	}

	// ADD ITEM TO SELECTED
	selectItem(obj): void {
		if (!obj.isSelected) {
			obj.isSelected = true;
			this.search.selected.push(obj);
			this.search.selected = sortBy(this.search.selected, 'name');
		} else {
			this.removeItem(obj);
		}
	}

	// REMOVE ITEM FROM SELECTED
	removeItem(obj): void {
		let item;
		switch (this.settings.type) {
			case 'bannerrestriction':
				item = this.search.list.find(({ name }) => name === obj.name);
				this.search.selected = reject(this.search.selected, { name: obj.name });
				break;
			default:
				item = this.search.list.find(({ id }) => id === obj.id);
				this.search.selected = reject(this.search.selected, { id: obj.id });
		}

		//IS THE ITEM LOADED
		if (item) {
			item.isSelected = false;
		}
	}

	clearQuicksearch(): void {
		this.search.qs = null;
	}

	// MODAL ACTIONS
	ok(): void {
		this.dialogRef.close(this.search.selected);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
