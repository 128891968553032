import { Component, OnInit } from '@angular/core';

import { AppService, GlobalService } from '@fm/services';

import { GlobalStore } from '@fmlib/interfaces';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'login-envs',
	templateUrl: './login-envs.component.html',
	styleUrls: ['./login-envs.component.less'],
})
export class LoginEnvsComponent implements OnInit {
	global: GlobalStore;
	public envOptions;

	constructor(
		private FM_App: AppService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.envOptions = [];

		if (this.global && this.global.config) {
			if (
				(this.global.config && this.global.config.server === 'local') ||
				this.global.config.server === 'development' ||
				this.global.config.server === 'test'
			) {
				this.envOptions.push({ key: 'development', name: 'DEV' });
				this.envOptions.push({ key: 'production', name: 'NA' });
				// this.envOptions.push({ key: 'production-eu', name: 'EU' });
			} else {
				this.envOptions.push({ key: 'production', name: 'NORTH_AMERICA' });
				// this.envOptions.push({ key: 'production-eu', name: 'EUROPEAN_UNION' });
			}
		}
	}

	updateEnv(env: string): void {
		this.FM_Global.setEnv(env);
		this.FM_App.initGlobal();
	}
}
