import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, MessageService } from '@fm/services';
import { SharedModule } from '../../shared/shared.module';

@Component({
	standalone: true,
	imports: [SharedModule],
	selector: 'batch-add',
	templateUrl: './batch-add-dialog.component.html',
})
export class BatchAddDialogComponent implements OnInit, OnDestroy {
	public batch;
	public batchTypes;
	public load_timer;
	public profile;
	public selected;
	public settings;
	public view;

	constructor(
		//
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<BatchAddDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(MessageService) private FM_Messages: MessageService
	) {}

	ngOnInit(): void {
		this.settings = {};
		this.batch = {};

		this.batchTypes = [
			{
				id: 'experiences',
				name: 'EXPERIENCE',
			},
			{
				id: 'payloads',
				name: 'PAYLOADS',
			},
			{
				id: 'sites',
				name: 'SITES',
			},
			{
				id: 'zones',
				name: 'ZONES',
			},
		];

		// SCOPE VARIABLES
		this.view = {
			isLoading: false,
			isFailed: false,
		};
	}

	// MODAL ACTIONS
	ok(form): boolean {
		if (this.view.isBusy) return true;

		if (!form.valid) {
			this.FM_Messages.addErrorMessage('INVALID_FORM');
			return false;
		}

		const batchLength = new Array(this.batch.count);
		const promises = [];

		this.settings.endpoint = this.FM_Api.connect(this.batch.type);

		batchLength.forEach((item, index) => {
			// let temp = FM_Data.flattenData(this.batch);

			// BATCH ITEM
			const temp = { name: this.batch.name + '_' + index };

			promises.push(this.settings.endpoint.post(temp));
		});

		Promise.all(promises).then(() => {
			this.load_timer = setTimeout(() => {
				this.view.isBusy = false;
				this.dialogRef.close(this.selected);
			}, 200);
		});
	}

	cancel(): void {
		this.dialogRef.close();
	}

	// CLEANUP
	ngOnDestroy(): void {
		clearTimeout(this.load_timer);
	}
}
