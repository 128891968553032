import {
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';

import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Observable } from 'rxjs';
import { debounceTime, startWith, switchMap } from 'rxjs/operators';

import { DataService } from '@fm/services';

import { isEqual, without } from 'lodash-es';

@Component({
	selector: 'filter-tags',
	templateUrl: './filter-tags.component.html',
	styleUrls: ['./filter-tags.component.less'],
})
export class FilterTagsComponent implements OnInit, OnChanges {
	@Input() filter;
	@Input() objtype;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	public currentFilter;
	public editor;
	public list: any[];
	public isOpen: boolean;

	filteredOptions: Observable<any[]>;
	myControl = new FormControl();

	@ViewChild('searchInput', { static: false }) private searchInput: ElementRef;

	constructor(private FM_Data: DataService) {
		this.FM_Data = FM_Data;
	}

	ngOnInit(): void {
		this.list = [];

		if (this.filter) {
			this.list = this.FM_Data.clone(this.filter);
		}

		this.filteredOptions = this.myControl.valueChanges.pipe(
			startWith(''),
			debounceTime(400),
			switchMap((value) => this.getTags(value))
		);
	}

	ngOnChanges(): void {
		if (!this.filter) {
			this.clearFilter();
		} else {
			this.currentFilter = this.FM_Data.clone(this.filter);
		}
	}

	open(): void {
		this.isOpen = true;
		setTimeout(() => {
			this.searchInput.nativeElement.focus();
		}, 0);
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
		this.isOpen = false;
	}

	runupdate(): void {
		// CLEAR THE PROP
		if (this.list.length === 0) {
			delete this.filter;
		} else {
			this.filter = this.list;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = this.FM_Data.clone(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	clearItem(item): void {
		this.list = without(this.list, item);
		this.runupdate();
	}

	setItem(event: MatAutocompleteSelectedEvent): void {
		event.option.deselect();
		this.searchInput.nativeElement.blur();
		this.myControl.reset();

		if (this.list.indexOf(event.option.value) === -1) {
			this.list.push(event.option.value);
			this.runupdate();
		}
	}

	getTags(val: string): Promise<any[]> {
		return this.FM_Data.getTagOptions(this.objtype, val);
	}

	clearFilter(): void {
		this.list = [];
		delete this.filter;
	}
}
