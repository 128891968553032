import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'broadcastMode' })
export class BroadcastModePipe implements PipeTransform {
	constructor(private translate: TranslateService) {}

	transform(modes: { mode: string; active: boolean }[]): string {
		const mode = modes.find(({ mode }) => mode === 'open');
		const text = mode && mode.active ? 'OPEN' : 'PRIVATE';
		return this.translate.instant(text);
	}
}
