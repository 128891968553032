import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { cloneDeep, isEqual } from 'lodash-es';

@Component({
	selector: 'filter-select',
	template: `<div class="filter-wrap">
		<div class="field-editor">
			<select class="fm-select" [(ngModel)]="filter" (change)="setValue()">
				<option [value]="undefined">All</option>
				<option
					[value]="op.id"
					*ngFor="let op of options; trackBy: 'index' | trackByProperty"
					[translate]="op.name"></option>
			</select>
			<span class="input-icons"><i class="fa-light fa-circle-chevron-down"></i></span>
		</div>
	</div>`,
})
export class FilterSelectComponent implements OnChanges {
	@Input() filter;

	@Output() filterChange = new EventEmitter<any>();
	@Output() update = new EventEmitter<void>();

	@Input() options;

	public currentFilter;

	ngOnChanges(): void {
		if (!this.filter || this.filter === '') {
			this.clearFilter();
		}
	}

	close(): void {
		this.clearFilter();
		this.runupdate();
	}

	runupdate(): void {
		if (this.filter === '') {
			delete this.filter;
		}

		if (!isEqual(this.currentFilter, this.filter)) {
			this.currentFilter = cloneDeep(this.filter);

			// FILTER OUTPUT
			this.filterChange.emit(this.filter);
			this.update.emit();
		}
	}

	setValue(): void {
		this.runupdate();
	}

	clearFilter(): void {
		delete this.filter;
	}
}
