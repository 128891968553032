<div class="section-actions" *ngIf="!readonly && !isFixed">
	<a [routerLink]="" (click)="chooseItems()" translate="ADD"></a>
	<a [routerLink]="" *ngIf="list?.length" (click)="removeAllItems()" translate="REMOVE_ALL"></a>
</div>

<ul class="fm-list" *ngIf="list?.length">
	<li class="fm-list-item" *ngFor="let s of list; trackBy: 'id' | trackByProperty">
		<div class="list-cell fm-list-detail">
			<div class="detail-main" *ngIf="type !== ProfileType.PARTNER">
				<span class="title" [innerText]="s.name"></span>
			</div>
			<div class="detail-main" *ngIf="type === ProfileType.PARTNER">
				<span class="title" [innerText]="s.companyName"></span>
			</div>
		</div>
		<div class="item-controls" *ngIf="!readonly && !isFixed">
			<a class="remove-item" [routerLink]="" (click)="removeItem(s)">
				<i class="fa fa-times"></i>
			</a>
		</div>
	</li>
</ul>
<div class="error-message" *ngIf="!isValid">
	<h4 translate="REQUIRED"></h4>
</div>
