import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ProfileType } from '@fmlib/enums';

import { AuthGuard } from './guards/auth.guard';

export const appRoutes: Routes = [
	{
		path: 'viewdata',
		loadChildren: () =>
			import('./components/viewdata/viewdata.module').then((m) => m.ViewDataModule),
	},
	{
		path: 'gwstatus/:id',
		loadComponent: () =>
			import('./components/gwstatus/gw-status.component').then((m) => m.GWStatusComponent),
	},
	{
		path: 'login',
		loadComponent: () =>
			import('./components/login/login.component').then((m) => m.LoginComponent),
	},
	{
		path: 'setpassword/:token',
		loadComponent: () =>
			import('./components/login/login.component').then((m) => m.LoginComponent),
	},
	{
		path: 'resetpassword/:token',
		loadComponent: () =>
			import('./components/login/login.component').then((m) => m.LoginComponent),
	},
	{
		path: 'dashboard',
		canLoad: [AuthGuard],
		loadComponent: () =>
			import('./components/dashboard/dashboard.component').then((m) => m.DashboardComponent),
	},
	{
		path: 'googleapi',
		loadComponent: () =>
			import('./components/googleapi/googleapi.component').then((m) => m.GoogleApiComponent),
	},
	{
		path: 'reports',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./components/reports/reports.module').then((m) => m.ReportsModule),
	},
	{
		path: 'exports',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./components/scheduledexports/scheduledexports.module').then(
				(m) => m.ExportsModule
			),
	},

	{
		path: 'requests',
		canLoad: [AuthGuard],
		loadChildren: () =>
			import('./components/requests/requests.module').then((m) => m.RequestModule),
	},

	// ADMINUSERS
	{
		path: 'adminusers/:id',
		data: { objtype: ProfileType.ADMINUSER },
		loadChildren: () =>
			import('./components/adminuser/adminuser.module').then((m) => m.AdminuserModule),
	},
	{
		path: 'adminusers',
		data: { objtype: ProfileType.ADMINUSER },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// APPS
	{
		path: 'apps/:id',
		data: { objtype: ProfileType.APP },
		loadChildren: () => import('./components/app/app.module').then((m) => m.AppModule),
	},
	{
		path: 'apps',
		data: { objtype: ProfileType.APP },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// ASSETS
	{
		path: 'assets/:id',
		data: { objtype: ProfileType.ASSET },
		loadChildren: () => import('./components/asset/asset.module').then((m) => m.AssetModule),
	},
	{
		path: 'assets',
		data: { objtype: ProfileType.ASSET },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// BEACONS
	{
		path: 'beacons/:id',
		data: { objtype: ProfileType.BEACON },
		loadChildren: () => import('./components/beacon/beacon.module').then((m) => m.BeaconModule),
	},
	{
		path: 'beacons',
		data: { objtype: ProfileType.BEACON },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// COMPANIES
	{
		path: 'companies/:id',
		data: { objtype: ProfileType.COMPANY },
		loadChildren: () =>
			import('./components/company/company.module').then((m) => m.CompanyModule),
	},
	{
		path: 'companies',
		data: { objtype: ProfileType.COMPANY },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// DEVICE
	{
		path: 'devices/:id',
		data: { objtype: 'device' },
		loadChildren: () => import('./components/device/device.module').then((m) => m.DeviceModule),
	},
	{
		path: 'devices',
		data: { objtype: 'device' },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// EXPERIENCE
	{
		path: 'experiences/:id',
		data: { objtype: ProfileType.EXPERIENCE },
		loadChildren: () =>
			import('./components/experience/experience.module').then((m) => m.ExperiencesModule),
	},
	{
		path: 'experiences',
		data: { objtype: ProfileType.EXPERIENCE },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// GATEWAY
	{
		path: 'gateways/:id',
		data: { objtype: ProfileType.GATEWAY },
		loadChildren: () =>
			import('./components/gateway/gateway.module').then((m) => m.GatwayModule),
	},
	{
		path: 'gateways',
		data: { objtype: ProfileType.GATEWAY },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// GEOFENCE
	{
		path: 'geofences/:id',
		data: { objtype: ProfileType.GEOFENCE },
		loadChildren: () =>
			import('./components/profile/profile.module').then((m) => m.ProfileModule),
	},
	{
		path: 'geofences',
		data: { objtype: ProfileType.GEOFENCE },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// JOURNEY
	{
		path: 'journeys/:id',
		data: { objtype: ProfileType.JOURNEY },
		loadChildren: () =>
			import('./components/journey/journey.module').then((m) => m.JourneyModule),
	},
	{
		path: 'journeys',
		data: { objtype: ProfileType.JOURNEY },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// NFC
	{
		path: 'nfcs/:id',
		data: { objtype: ProfileType.NFC },
		loadChildren: () => import('./components/nfc/nfc.module').then((m) => m.NfcModule),
	},
	{
		path: 'nfcs',
		data: { objtype: ProfileType.NFC },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// PARTNERS
	{
		path: 'partners/:id',
		data: { objtype: ProfileType.PARTNER },
		loadChildren: () =>
			import('./components/partner/partner.module').then((m) => m.PartnersModule),
	},
	{
		path: 'partners',
		data: { objtype: ProfileType.PARTNER },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// PAYLOADS
	{
		path: 'payloads/:id',
		data: { objtype: ProfileType.PAYLOAD },
		loadChildren: () =>
			import('./components/payload/payload.module').then((m) => m.PayloadModule),
	},
	{
		path: 'payloads',
		data: { objtype: ProfileType.PAYLOAD },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// PROGRAMS
	{
		path: 'programs/:id',
		data: { objtype: ProfileType.PROGRAM },
		loadChildren: () =>
			import('./components/program/program.module').then((m) => m.ProgramModule),
	},
	{
		path: 'programs',
		data: { objtype: ProfileType.PROGRAM },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// PUSHES
	{
		path: 'pushes/:id',
		data: { objtype: ProfileType.PUSH },
		loadChildren: () => import('./components/push/push.module').then((m) => m.PushModule),
	},
	{
		path: 'pushes',
		data: { objtype: ProfileType.PUSH },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// SITES
	{
		path: 'sites/:id',
		data: { objtype: ProfileType.SITE },
		loadChildren: () => import('./components/site/site.module').then((m) => m.SiteModule),
	},
	{
		path: 'sites',
		data: { objtype: ProfileType.SITE },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// TASKS
	{
		path: 'tasks/:id',
		data: { objtype: ProfileType.TASK },
		loadChildren: () => import('./components/task/task.module').then((m) => m.TasksModule),
	},
	{
		path: 'tasks',
		data: { objtype: ProfileType.TASK },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// ZONES
	{
		path: 'zones/:id',
		data: { objtype: ProfileType.ZONE },
		loadChildren: () => import('./components/zone/zone.module').then((m) => m.ZoneModule),
	},
	{
		path: 'zones',
		data: { objtype: ProfileType.ZONE },
		loadComponent: () =>
			import('./components/search/search.component').then((m) => m.SearchComponent),
	},

	// PLATFORM TASKS
	{
		path: 'platformtasks',
		loadChildren: () =>
			import('./components/platformtasks/platformtask.module').then(
				(m) => m.PlatformtasksModule
			),
	},

	// FAILED MATCHES LOAD DASHBOARD
	{ path: '', redirectTo: '/', pathMatch: 'full' },
	{ path: '**', redirectTo: '/', pathMatch: 'full' },
];

@NgModule({
	imports: [
		RouterModule.forRoot(appRoutes, {
			enableTracing: false, // <-- debugging purposes only
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
