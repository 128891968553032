import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ApiService, GlobalService, ListData } from '@fm/services';
import { GlobalStore } from '@fmlib/interfaces';

@Component({
	selector: 'notifications-add-dialog',
	templateUrl: './notifications-add-dialog.component.html',
})
export class NotificationsAddDialogComponent implements OnInit {
	global: GlobalStore;

	public mode;
	public adminuser;
	public notification;
	public isBusy: boolean;
	public isLoading: boolean;
	public notifTypes: ListData[];
	public severityLevels: ListData[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: { mode: string },
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<NotificationsAddDialogComponent>,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService
	) {
		// LOAD THE GLOBALS
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.mode = this.data.mode;

		this.isLoading = true;

		this.notification = {};

		this.notifTypes = [
			{
				id: 'unknown',
				name: 'unknown',
			},
			{
				id: 'platform',
				name: 'platform',
			},
			{
				id: 'platformTask',
				name: 'platformTask',
			},
			{
				id: 'maintenance',
				name: 'maintenance',
			},
		];

		this.severityLevels = [
			{
				id: 'info',
				name: 'Info',
			},
			{
				id: 'warning',
				name: 'Warning',
			},
			{
				id: 'error',
				name: 'Error',
			},
		];

		if (this.mode !== 'add') {
			this.FM_Api.connect('adminusers').get(
				{
					id: this.global.user.id,
					select: 'alertRegistration',
				},
				(data) => {
					this.adminuser = data;

					this.isLoading = false;
				}
			);
		} else {
			setTimeout(() => {
				this.isLoading = false;
			}, 400);
		}
	}

	// MODAL ACTIONS
	ok(): void {
		this.isBusy = true;
		if (this.mode === 'add') {
			this.notification.adminUser = this.global.user.id;

			// SAVE ALERT
			this.FM_Api.connect('notifications').post(this.notification, () => {
				this.isBusy = false;
				this.dialogRef.close();
			});
		} else {
			const temp = {
				alertRegistration: this.adminuser.alertRegistration.filter(
					({ isActive }) => isActive
				),
			};

			// SAVE ALERT REG
			this.FM_Api.connect('adminusers')
				.put({ id: this.global.user.id }, temp)
				.subscribe(() => {
					this.isBusy = false;
					this.dialogRef.close();
				});
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
