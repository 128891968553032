import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import {
	ApiService,
	DataService,
	GlobalService,
	MessageService,
	ProfileService,
} from '@fm/services';

import { GlobalStore } from '@fmlib/interfaces';
import { EMPTY, catchError } from 'rxjs';

@Component({
	selector: 'create-basic-dialog',
	templateUrl: './create-basic-dialog.component.html',
})
export class CreateBasicDialogComponent implements OnInit {
	global: GlobalStore;
	obj;
	profile;
	regions;
	settings;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<CreateBasicDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(DataService) private FM_Data: DataService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(MessageService) private FM_Message: MessageService,
		@Inject(ProfileService) private FM_Profile: ProfileService
	) {
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.profile = {
			type: this.FM_Data.clone(this.data.objtype),
			isLoading: false,
			isFailed: false,
		};

		this.settings = {
			endpoint: this.FM_Api.connect(this.profile.type + 's'),
		};

		if (this.data.selected) {
			this.obj = this.FM_Data.clone(this.data.selected);
			this.profile.title = 'EDIT_' + this.profile.type.toUpperCase();
		} else {
			this.obj = this.FM_Profile.getDefault(this.profile.type);
			this.profile.title = 'ADD_' + this.profile.type.toUpperCase();
		}

		if (this.profile.type === 'district') {
			this.profile.isLoading = true;

			this.FM_Api.connect('regions').query(
				{
					page: 1,
					per_page: 1000,
					sort: 'name',
				},
				(data) => {
					this.regions = data;
					this.profile.isLoading = false;
				}
			);
		} else {
			this.profile.isLoading = false;
		}
	}

	saveComplete(response, message): void {
		this.FM_Message.addSuccessMessage(message);
		this.dialogRef.close(response);
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	saveError(): void {
		this.FM_Message.addErrorMessage('REQUEST_ERROR');
		setTimeout(() => {
			this.profile.isBusy = false;
		}, 200);
	}

	save(): void {
		this.profile.isBusy = true;

		// PREPARE THE SAVE
		let temp: any = {
			name: this.obj.name,
			isActive: this.obj.isActive,
			description: this.obj.description,
			tags: this.obj.tags,
		};

		if (this.obj.region) {
			temp.region = this.obj.region;
		}

		// FLATTENT THE DATA
		temp = this.FM_Data.flattenData(temp);

		// SAVE OR UPDATE
		if (!this.obj.id) {
			this.settings.endpoint.post(
				temp,
				(response) => {
					this.saveComplete(response, 'CREATED_' + +this.profile.type.toUpperCase());
				},
				() => {
					this.saveError();
				}
			);
		} else {
			this.settings.endpoint
				.put({ id: this.obj.id }, temp)
				.pipe(
					catchError(() => {
						this.saveError();
						return EMPTY;
					})
				)
				.subscribe((response) => {
					this.saveComplete(response, 'UPDATED_' + +this.profile.type.toUpperCase());
				});
		}
	}

	compareIds(a, b): boolean {
		const test = a && b ? a.id === b.id : a === b;
		return test;
	}

	// SAVE
	ok(form): void {
		if (form.valid) {
			this.save();
		} else {
			this.FM_Message.addErrorMessage('INVALID_FORM');
		}
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
