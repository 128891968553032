import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MatDialog } from '@angular/material/dialog';
import { AuditDialogComponent } from '../../audit/audit-dialog.component';

import { ModalSizes, ProfileType } from '@fmlib/enums';
import { ApiService, DATE_NULL, GlobalService } from '@fm/services';
import { App, Gateway, GlobalStore, SearchParams } from '@fmlib/interfaces';

import { EMPTY, catchError, tap } from 'rxjs';

@Component({
	selector: 'profile-metadata',
	templateUrl: './profile-metadata.component.html',
	styleUrls: ['./profile-metadata.component.less'],
})
export class ProfileMetadataComponent implements OnInit {
	@Input() obj;
	@Input() objtype: ProfileType;

	global: GlobalStore;
	audits;
	view;

	seenApp: App;
	seenGateway: Gateway;

	constructor(
		public router: Router,
		private dialog: MatDialog,
		private FM_Api: ApiService,
		private FM_Global: GlobalService
	) {
		this.global = this.FM_Global.get();
	}

	get lastSeenApp(): string {
		return this.seenApp ? this.seenApp.name : 'third party app';
	}

	get lastSeenGateway(): string {
		return this.seenGateway ? this.seenGateway.name : 'third party gateway';
	}

	ngOnInit(): void {
		this.view = {
			checkUnique: false,
			showEditor: false,
			lockname: false,
		};

		if (
			this.objtype === ProfileType.APP ||
			this.objtype === ProfileType.COMPANY ||
			this.objtype === ProfileType.ZONE
		) {
			this.view.checkUnique = true;
		}

		if (this.objtype === ProfileType.BEACON) {
			if (this.obj.lastSeenApp) {
				this.FM_Api.get(`apps/${this.obj.lastSeenApp}`)
					.pipe(
						tap((res) => {
							this.seenApp = res;
						}),
						catchError(() => {
							return EMPTY;
						})
					)
					.subscribe();
			}

			if (this.obj.lastSeenGateway) {
				this.FM_Api.get(`gateways/${this.obj.lastSeenGateway}`)
					.pipe(
						tap((res) => {
							this.seenGateway = res;
						}),
						catchError(() => {
							return EMPTY;
						})
					)
					.subscribe();
			}
		}

		/*
		if(this.objtype === 'company' && !this.global.user.isSuper){
			this.view.lockname = true;
		}
		*/

		if (this.obj && this.obj.id && !this.audits) {
			this.getAudits();
		}
	}
	goToApp(): void {
		if (this.seenApp) {
			this.router.navigate(['apps', this.seenApp.id]);
		}
	}

	goToGateway(): void {
		if (this.seenGateway) {
			this.router.navigate(['gateways', this.seenGateway.id]);
		}
	}

	isNullDate(date): boolean {
		return !date || date === DATE_NULL;
	}

	showEditor(): void {
		this.view.showEditor = true;
	}

	getAudits(): void {
		this.audits = {};

		const params: SearchParams = {
			page: 1,
			per_page: 1,
		};

		// LAST UPDATED AUDIT
		params.sort = '-updated';
		params.conditions = {
			action: 'update',
			docId: this.obj.id,
			resource: this.objtype,
		};

		this.FM_Api.connect('audits').query(params, (data) => {
			if (data.length) {
				this.audits.updated = data[0];
			}
		});

		// FIRST CREATED AUDIT
		params.sort = '-created';
		params.conditions = {
			action: 'insert',
			docId: this.obj.id,
			resource: this.objtype,
		};

		this.FM_Api.connect('audits').query(params, (data) => {
			if (data.length) {
				this.audits.created = data[0];
			}
		});
	}

	loadAudits(action): void {
		this.dialog.open(AuditDialogComponent, {
			panelClass: ModalSizes.Search,
			data: {
				obj: this.obj,
				objtype: this.objtype,
				filter: {
					action: action,
				},
			},
		});
	}
}
