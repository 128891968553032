import { Component, Inject } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'confirm-delete',
	templateUrl: './confirm-delete-dialog.component.html',
	styleUrls: ['./confirm-delete-dialog.component.less'],
})
export class ConfirmDeleteDialogComponent {
	public message: string;
	public obj;
	public userinput;
	public remove;

	constructor(
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	ngOnInit(): void {
		this.userinput = {};

		this.remove = {
			warning: 'WARNING_REMOVE',
			agreement: 'REMOVE_AGREE',
		};

		this.message = this.data.message ? this.data.message : '';

		if (this.data.obj) {
			this.obj = this.data.obj;
		}
	}

	ok(): void {
		this.dialogRef.close(true);
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
