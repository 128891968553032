export enum ProfileType {
	ADMINUSER = 'adminuser',
	APP = 'app',
	ASSET = 'asset',
	BEACON = 'beacon',
	COMPANY = 'company',
	DEVICE = 'device',
	DISTRICT = 'district',
	DIVISION = 'division',
	EXPERIENCE = 'experience',
	GATEWAY = 'gateway',
	GEOFENCE = 'geofence',
	GROUP = 'group',
	JOURNEY = 'journey',
	PARTNER = 'partner',
	PAYLOAD = 'payload',
	PLATFORMTASK = 'platformtask',
	PROGRAM = 'program',
	PUSH = 'push',
	NFC = 'nfc',
	REGION = 'region',
	SITE = 'site',
	SUPPLIER = 'supplier',
	TASK = 'task',
	TASKINSTANCES = 'taskinstances',
	ZONE = 'zone',

	SCHEDULEDEXPORT = 'scheduledexport',
}
