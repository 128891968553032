import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[zero-blank]',
})
export class ZeroBlankDirective implements AfterViewInit {
	@HostListener('ngModelChange', ['$event'])
	public onKeyup(): void {
		setTimeout(() => {
			this.updateValue();
		}, 1);
	}

	constructor(private elm: ElementRef) {}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.updateValue();
		}, 1);
	}

	updateValue(): void {
		if (this.elm.nativeElement.value === '0') {
			this.elm.nativeElement.value = null;
		}
	}
}
