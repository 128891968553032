import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ModalSizes } from '@fmlib/enums';
import { ApiService, GlobalService, MessageService } from '@fm/services';
import { AdminUser, GlobalStore } from '@fmlib/interfaces';

import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { cloneDeep, some } from 'lodash-es';

@Component({
	selector: 'admin-profile-dialog',
	templateUrl: './admin-profile-dialog.component.html',
})
export class AdminProfileDialogComponent implements OnInit {
	public list_timer;
	public map_timer1;
	public map_timer2;

	global: GlobalStore;
	public adminuser;
	public view;
	public settings;

	constructor(
		@Inject(Router) private router: Router,
		@Inject(MatDialog) private dialog: MatDialog,
		@Inject(MatDialogRef) private dialogRef: MatDialogRef<AdminProfileDialogComponent>,
		@Inject(MAT_DIALOG_DATA) private data: any,
		@Inject(ApiService) private FM_Api: ApiService,
		@Inject(GlobalService) private FM_Global: GlobalService,
		@Inject(MessageService) private FM_Messages: MessageService
	) {
		// MODALS NEEDS THE GLOBALS...
		this.global = this.FM_Global.get();
	}

	ngOnInit(): void {
		this.adminuser = cloneDeep(this.data.user);

		this.settings = {
			populate: 'company groups',
		};

		this.view = {
			setPassword: false,
		};
	}

	viewProfile(): void {
		this.router.navigate(['adminusers', this.data.user.id]);
		this.dialogRef.close();
	}

	enable2FA(): void {
		if (this.adminuser.require2FA) {
			this.setupMFA();
		}
	}

	async setupMFA(): Promise<void> {
		const { MFADialogComponent } = await import(
			/* webpackPrefetch: true */
			'../../mfa/mfa-dialog.component'
		);

		const dialogRef = this.dialog.open(MFADialogComponent, {
			panelClass: ModalSizes.Basic,
			data: {
				adminuser: this.adminuser,
			},
		});

		dialogRef.afterClosed().subscribe((active) => {
			if (active) {
				this.FM_Api.connect('adminusers')
					.put({ id: this.adminuser.id }, { require2FA: true })
					.subscribe(() => {
						this.adminuser.require2FA = true;
						this.FM_Messages.addSuccessMessage('MFA_SETUP_COMPLETE');
					});
			} else {
				this.adminuser.require2FA = false;
			}
		});
	}

	// MODAL ACTIONS
	ok(): void {
		this.view.isBusy = true;

		// save user
		const temp: Partial<AdminUser> = {
			firstname: this.adminuser.firstname,
			lastname: this.adminuser.lastname,
			email: this.adminuser.email,
			require2FA: this.adminuser.require2FA,
		};

		// DO WE SEND UP NEW PASSWORD
		if (this.view.setPassword) {
			temp.password = this.adminuser.password.trim();
		}

		this.FM_Api.put('self', temp)
			.pipe(
				catchError(() => {
					this.FM_Messages.addSuccessMessage('ERROR');
					return EMPTY;
				})
			)
			.subscribe((response) => {
				this.global.user = response;

				// NEED POPULATE TO WORK
				this.global.user.groups = this.data.user.groups;
				this.global.user.isSuper = some(this.global.user.groups, { isSuper: true });
				this.global.user.showSuper = some(this.global.user.groups, { isSuper: true });

				setTimeout(() => {
					this.FM_Messages.addSuccessMessage('Your profile has been updated');
					this.view.isBusy = true;
					this.dialogRef.close();
				}, 200);
			});
	}

	cancel(): void {
		this.dialogRef.close();
	}
}
