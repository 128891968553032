<section class="filter-panel">
	<div class="fm-sidebar fm-filterlist">
		<div class="filter-section">
			<div class="filter-group">
				<fm-filter class="fm-filter active" [ngClass]="{ active: true }">
					<h3 translate="FILTER_BY_DATE"></h3>
					<div class="filter-wrap">
						<ul>
							<li
								class="fm-savedfilter"
								*ngFor="let filter of dateFilters; trackBy: 'id' | trackByProperty"
								(click)="loadFilter(filter)"
								[ngClass]="{ active: isActive(filter) }">
								<a [routerLink]="">
									<span class="filter-status"><i class="fa fa-check"></i></span>
									{{ filter.name }}
								</a>
							</li>
						</ul>
					</div>
				</fm-filter>
				<fm-filter class="fm-filter" [ngClass]="{ active: filters.action }">
					<h3 translate="FILTER_BY_ACTION"></h3>
					<div class="filter-wrap">
						<ul>
							<li class="fm-savedfilter" [ngClass]="{ active: !filters.action }">
								<a [routerLink]="" (click)="filterAction()" translate="ALL"></a>
							</li>
							<li
								class="fm-savedfilter"
								[ngClass]="{ active: filters.action === 'update' }">
								<a
									[routerLink]=""
									(click)="filterAction('update')"
									translate="UPDATE"></a>
							</li>
							<li
								class="fm-savedfilter"
								[ngClass]="{ active: filters.action === 'insert' }">
								<a
									[routerLink]=""
									(click)="filterAction('insert')"
									translate="INSERT"></a>
							</li>
						</ul>
					</div>
				</fm-filter>
			</div>
		</div>
	</div>
</section>

<!-- LIST PANEL -->
<section class="list-panel">
	<section
		class="fm-list-wrap"
		infinite-scroll
		[infiniteScrollDisabled]="search.isLoading"
		[scrollWindow]="false"
		[infiniteScrollThrottle]="0"
		(scrolled)="loadList()">
		<div
			class="fm-message notice"
			*ngIf="search.total === 0 && !search.isLoading && !search.isFailed">
			<span translate="NO_HISTORY"></span>
		</div>

		<div class="fm-list">
			<a
				[routerLink]=""
				class="fm-list-item"
				*ngFor="let item of search.list; trackBy: 'id' | trackByProperty"
				(click)="selectItem(item)"
				[ngClass]="{ selected: item.id === selected.id }">
				<div class="list-cell fm-list-detail">
					<div class="detail-main">
						<span [innerText]="item.created | customDate"></span>
						<ul class="detail">
							<li><label translate="CHANGES"></label> : {{ item.mods.length }}</li>
						</ul>
					</div>
					<span class="list-action" [translate]="item?.action | uppercase"></span>
				</div>
			</a>
		</div>

		<div class="panel-loader">
			<fm-loader [loading]="search.isLoading" [error]="search.isFailed"></fm-loader>
		</div>
	</section>

	<list-status
		[loading]="search.isLoading"
		[count]="search.count"
		[total]="search.total"></list-status>
</section>

<!-- SELECTED PANEL -->
<section class="detail-panel">
	<div *ngIf="!selected">
		<div class="fm-message notice">
			<span translate="NO_DETAIL"></span>
		</div>
	</div>

	<section class="audit-detail" *ngIf="selected">
		<h4 class="panel-title" [innerText]="selected.created | customDate"></h4>

		<div class="audit-info" [ngClass]="selected.action">
			<i class="fa fa-user"></i>

			<div class="audit-status">
				<h3 [innerText]="selected.action"></h3>
				<span>by: {{ selected.adminUserName }}</span>
			</div>
		</div>

		<div class="audit-changes">
			<div
				class="form-row"
				*ngFor="let mod of selected.mods_formatted; trackBy: 'index' | trackByProperty">
				<label class="label" [translate]="mod.key"></label>
				<div class="field-info" *ngIf="mod.key === 'IMAGE'">
					<img class="audit-image" [src]="mod.value" />
				</div>
				<div
					class="field-info"
					*ngIf="
						mod.key !== 'IMAGE' && mod.key !== 'ATTRIBUTES' && mod.key !== 'PROPERTIES'
					">
					<ul class="change-list">
						<li
							*ngFor="let change of mod.changes; trackBy: 'index' | trackByProperty"
							[translate]="change"></li>
					</ul>
				</div>
				<div
					class="field-info"
					*ngIf="mod.key === 'ATTRIBUTES' || mod.key === 'PROPERTIES'">
					<table class="change-list" *ngIf="mod.changes">
						<tr *ngFor="let change of mod.changes; trackBy: 'index' | trackByProperty">
							<td>{{ change.key }}</td>
							<td>{{ change.value | emptyString }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</section>
</section>
